import React, { useRef } from "react";
import "./css/contact.css";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import Footer from "./footer";
import Iframe from "react-iframe";
import { NavLink } from "react-router-dom";
function Contect() {
  const form = useRef();
  const sendEmail = (e) => {
    if (
      document.getElementById("funame").value !== "" &&
      document.getElementById("luname").value !== "" &&
      document.getElementById("email").value !== "" &&
      document.getElementById("pnumber").value !== "" &&
      document.getElementById("subject").value !== "" &&
      document.getElementById("massage").value !== ""
    ) {
      document.getElementById("contactloddingofbutton").style.display = "block";
      document.getElementById("sendContact").style.display = "none";
      e.preventDefault();

      {
        {
          /* this email is setup on mithasultan09@gmail.com on https://dashboard.emailjs.com/*/
        }
      }
      emailjs
        .sendForm(
          "service_nea9mza",
          "template_1bw454b",
          form.current,
          "m8yfPzNmL4V4LholZ"
        )
        .then(
          (result) => {
            console.log(result.text);
            document.getElementById("funame").value = "";
            document.getElementById("luname").value = "";
            document.getElementById("email").value = "";
            document.getElementById("pnumber").value = "";
            document.getElementById("subject").value = "";
            document.getElementById("massage").value = "";
            document.getElementById("contactunsuccessmsg").style.display =
              "none";
            document.getElementById("contactemptyfuild").style.display = "none";
            document.getElementById("Contacttsuceesmsg").style.display =
              "block";
            document.getElementById("contactloddingofbutton").style.display =
              "none";
            document.getElementById("sendContact").style.display = "block";
          },
          (error) => {
            console.log(error.text);
            document.getElementById("Contacttsuceesmsg").style.display = "none";
            document.getElementById("contactemptyfuild").style.display = "none";
            document.getElementById("contactunsuccessmsg").style.display =
              "block";
            document.getElementById("contactloddingofbutton").style.display =
              "none";
            document.getElementById("sendContact").style.display = "block";
          }
        );
    } else {
      e.preventDefault();
      document.getElementById("Contacttsuceesmsg").style.display = "none";
      document.getElementById("contactunsuccessmsg").style.display = "none";
      document.getElementById("contactemptyfuild").style.display = "block";
      return false;
    }
  };
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  return (
    <>
      <motion.div
        className="contactus-main-wrapper"
        initial="hidden"
        animate="show"
        variants={container}
      >
        <div className="herowrapper">
          <div className="hero-main-partofthebox">
            <div className="hero-overlay"></div>
            {/* <motion.img
              src="/assets/img/dataManegment.jpg"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              alt=""
            /> */}
            <div className="contact-hero-wrapper">
              <div className="contact-hero-wrapper-grid">
                <div className="contact-wrapper-grid-left">
                  <div className="contact-grid-left-inner">
                    <div className="contact-grid-left-inner-headding">
                      <p>Contact</p>
                    </div>
                  </div>
                  <div className="contact-grid-left-inner">
                    <div className="contact-grid-left-inner-detailes">
                      <div>
                        <h3>CloudAvent HQ</h3>
                        <p>
                          44679 Endicott Drive
                          <br />
                          Ashburn,&nbsp;VA&nbsp;20147
                        </p>
                        <h3>Contact Us</h3>
                        <a href="mailto:info@cloudavent.com">
                          info@cloudavent.com
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contact-wrapper-grid-right">
                  <Iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3098.3178979671566!2d-77.4568798848124!3d39.05366897954689!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b63958e95e2613%3A0xf9408ec80d6420fc!2s44679%20Endicott%20Dr%2C%20Ashburn%2C%20VA%2020147%2C%20USA!5e0!3m2!1sen!2sin!4v1677128859246!5m2!1sen!2sin"
                    id="myId"
                    className="mapwithback"
                    display="initial"
                    position="absolute"
                    loading="lazy"
                  ></Iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact_wrapper">
          <div className="contectimgcontainer">
            <form ref={form} onSubmit={sendEmail}>
              <div className="contact_form">
                <div style={{ width: "100%" }}>
                  <motion.input
                    type="text"
                    name="funame"
                    id="funame"
                    placeholder="*First Name"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.05 }}
                    variants={{
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: 10, opacity: 0 },
                    }}
                    style={{ width: "48%", marginRight: "2%" }}
                  />
                  <motion.input
                    type="text"
                    name="luname"
                    id="luname"
                    placeholder="*Last Name"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.05 }}
                    variants={{
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: 10, opacity: 0 },
                    }}
                    style={{ width: "48%", marginLeft: "2%" }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <motion.input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="*E-mail"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.1 }}
                    variants={{
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: 10, opacity: 0 },
                    }}
                    style={{ width: "48%", marginRight: "2%" }}
                  />
                  <motion.input
                    type="text"
                    name="pnumber"
                    id="pnumber"
                    placeholder="*Phone Number"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.05 }}
                    variants={{
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: 10, opacity: 0 },
                    }}
                    style={{ width: "48%", marginLeft: "2%" }}
                  />
                </div>
                <motion.input
                  type="text"
                  name="subject"
                  id="subject"
                  placeholder="*Subject"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.15 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 10, opacity: 0 },
                  }}
                />
                <motion.label
                  htmlFor="massage"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 10, opacity: 0 },
                  }}
                  style={{ color: "#777" }}
                >
                  *Message
                </motion.label>
                <motion.textarea
                  name="massage"
                  id="massage"
                  rows="10"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.25 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 10, opacity: 0 },
                  }}
                ></motion.textarea>
                <div className="contactbuttunncommentcontainer">
                  <motion.button
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.3 }}
                    variants={{
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: 10, opacity: 0 },
                    }}
                    type="submit"
                  >
                    <span className="sendContact" id="sendContact">
                      Send
                    </span>
                    <i
                      className="fa fa-spinner contactloddingofbutton"
                      id="contactloddingofbutton"
                    ></i>
                  </motion.button>
                  <div style={{ width: "100%", marginLeft: "0.5rem" }}>
                    <p className="Contacttsuceesmsg" id="Contacttsuceesmsg">
                      Your Message is sent Successfully
                    </p>
                    <p className="contactunsuccessmsg" id="contactunsuccessmsg">
                      Something went wrong, your message isn't sent. Try
                      again!!!
                    </p>
                    <p className="contactunsuccessmsg" id="contactemptyfuild">
                      You have empty fields, fill them first.
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/*  <div className="box-for-background">
          <div className="box-for-background-inner">
            <div className="box-for-background-contact-circule-1"></div>
            <div className="box-for-background-contact-circule-2"></div>
            <div className="box-for-background-contact-circule-3"></div>
          </div>
        </div> */}
      </motion.div>
      <Footer />
    </>
  );
}

export default Contect;
