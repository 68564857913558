import React from "react";
import "./css/Cloud.css";
import "./css/hero.css";
import { motion } from "framer-motion";
import Footer from "./footer";
function Cloud() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  return (
    <motion.div
      variants={container}
      className="Cloud-wrapper"
      initial="hidden"
      animate="show"
    >
      <div className="herowrapper">
        <div className="hero-main-partofthebox">
          <div className="hero-overlay"></div>
          <motion.img
            src="/assets/img/cloud.jpg"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            alt=""
          />
          <div className="headdingherosection">
            <motion.h2
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 30, opacity: 0 },
              }}
            >
              Cloud
            </motion.h2>
          </div>
        </div>
      </div>
      <div className="cloud-detailes-wrapper">
        <div className="cloud-detailes-wrapper-headding">
          <div className="detailes-wrapper-headding-left">
            <motion.img
              src="/assets/img/Cloud.svg"
              width="100%"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: -30, opacity: 0 },
              }}
              alt=""
            />
          </div>
          <div className="detailes-wrapper-headding-right">
            {/*   <motion.h3
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 30, opacity: 0 },
              }}
            >
              Cloud
            </motion.h3> */}
            <motion.p
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 30, opacity: 0 },
              }}
            >
              We help establish the most efficient, flexible, secure and cost
              effective on-premise, cloud-based and hybrid infrastructures -
              from strategy to further management and maintenance. Our wealth of
              experience implementing SaaS, IaaS, and PaaS cloud solutions and
              tools will help you smoothly migrate to the modern IT
              infrastructure.
            </motion.p>
            <div className="Cloude-services-wrapper">
              <motion.h3
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: 30, opacity: 0 },
                }}
              >
                Our Cloud Services:
              </motion.h3>
              <motion.ul
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: 30, opacity: 0 },
                }}
              >
                <li>Multi cloud architecture strategy</li>
                <li>Cloud migration at scale</li>
                <li>Hybrid cloud architecture & design</li>
                <li>Cloud Financial Operations</li>
              </motion.ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </motion.div>
  );
}

export default Cloud;
