import "./App.css";
import Navbar from "./components/navbar";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Footer from "./components/footer";
import Career from "./components/Career";

import Herosection from "./components/herosection";
import Aboutus from "./components/AbouteUs";
import WhatWeOffer from "./components/WhatWeOffer";
import PramotionVideo from "./components/pramotionVideo";
import Partners from "./components/Partners";
import Contect from "./components/contect";
import CaseStudieshome from "./components/caseStudieshome";
import CaseStudies from "./components/caseStudies";
import WhatWeOfferHome from "./components/WhatWeOfferHome";
import ScrollToTop from "./components/ScrollToTop";
import CaseStudiesArticale from "./components/caseStudiesArticale";
import Recapinfo from "./components/recapinfo";
import Capabilitys from "./components/capabilitys";
import Cloud from "./components/Cloud";
import DataManagementAnalytics from "./components/DataManagementAnalytics";
import DevSecOps from "./components/DevSecOps";
import ModernDelivery from "./components/ModernDelivery";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" exect element={<Home />} />
        </Routes>
        <Routes>
          <Route path="/WhatWeOffer" element={<WhatWeOffer />} />
        </Routes>
        <Routes>
          <Route path="/CaseStudies" element={<CaseStudies />} />
        </Routes>
        <Routes>
          <Route
            path="/CaseStudies/article"
            element={<CaseStudiesArticale />}
          />
        </Routes>
        <Routes>
          <Route path="/Career" element={<Career />} />
        </Routes>
        <Routes>
          <Route path="/Contect" element={<Contect />} />
        </Routes>
        <Routes>
          <Route path="/capabilities/cloud" element={<Cloud />} />
        </Routes>
        <Routes>
          <Route
            path="/capabilities/data"
            element={<DataManagementAnalytics />}
          />
        </Routes>
        <Routes>
          <Route path="/capabilities/devSecOps" element={<DevSecOps />} />
        </Routes>
        <Routes>
          <Route
            path="/capabilities/ModernDelivery"
            element={<ModernDelivery />}
          />
        </Routes>
      </Router>
    </>
  );
}
function Home() {
  return (
    <div className="main_container">
      <div className="homepagecontainer">
        <Herosection />
        <Recapinfo />
        <Capabilitys />
      </div>
      <Footer />
    </div>
  );
}

export default App;
