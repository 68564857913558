import React from "react";
import "./css/Partners.css";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";

import partner1 from "../images/aws.png";
import partner2 from "../images/microsoft-logo.png";
import partner3 from "../images/boomi-logo.png";
import partner4 from "../images/cluod-bees-logo.png";
import partner5 from "../images/lightbend-logo.png";
import partner6 from "../images/Chef-logo.png";
import partner7 from "../images/lumigo-logo.png";
import partner8 from "../images/rafay-logo.png";
function Partners() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  return (
    <motion.div
      variants={container}
      className="Wrapper"
      initial="hidden"
      animate="show"
    >
      <div className="partnercontainer">
        <div className="partner-main-partofthebox">
          <div className="partner-main-heading">
            <motion.h1
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 20, opacity: 0 },
              }}
            >
              Platform Partners
            </motion.h1>
            <motion.p
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.02 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 20, opacity: 0 },
              }}
            >
              Know about our Platform Partners.
            </motion.p>
          </div>
          <div className="partners-images-box">
            <motion.img
              src={partner1}
              width="200"
              alt=""
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.04 }}
              variants={{
                visible: { y: 0, opacity: 0.5 },
                hidden: { y: 20, opacity: 0 },
              }}
            />
            <motion.img
              src={partner2}
              width="200"
              alt=""
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.06 }}
              variants={{
                visible: { y: 0, opacity: 0.5 },
                hidden: { y: 20, opacity: 0 },
              }}
            />
            <motion.img
              src={partner3}
              width="200"
              alt=""
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.08 }}
              variants={{
                visible: { y: 0, opacity: 0.5 },
                hidden: { y: 20, opacity: 0 },
              }}
            />
            <motion.img
              src={partner4}
              width="200"
              alt=""
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.1 }}
              variants={{
                visible: { y: 0, opacity: 0.5 },
                hidden: { y: 20, opacity: 0 },
              }}
            />
            <motion.img
              src={partner5}
              width="200"
              alt=""
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.12 }}
              variants={{
                visible: { y: 0, opacity: 0.5 },
                hidden: { y: 20, opacity: 0 },
              }}
            />
            <motion.img
              src={partner6}
              width="200"
              alt=""
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.14 }}
              variants={{
                visible: { y: 0, opacity: 0.5 },
                hidden: { y: 20, opacity: 0 },
              }}
            />
            <motion.img
              src={partner7}
              width="200"
              alt=""
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.16 }}
              variants={{
                visible: { y: 0, opacity: 0.5 },
                hidden: { y: 20, opacity: 0 },
              }}
            />
            <motion.img
              src={partner8}
              width="200"
              alt=""
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.18 }}
              variants={{
                visible: { y: 0, opacity: 0.5 },
                hidden: { y: 20, opacity: 0 },
              }}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Partners;
