import React from "react";
import "./css/Cloud.css";
import "./css/hero.css";
import { motion } from "framer-motion";
import Footer from "./footer";
function DevSecOps() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  return (
    <motion.div
      variants={container}
      className="Cloud-wrapper"
      initial="hidden"
      animate="show"
    >
      <div className="herowrapper">
        <div className="hero-main-partofthebox">
          <div className="hero-overlay"></div>
          <motion.img
            src="/assets/img/devops.jpg"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            alt=""
          />
          <div className="headdingherosection">
            <motion.h2
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 30, opacity: 0 },
              }}
            >
              DevSecOps
            </motion.h2>
          </div>
        </div>
      </div>
      <div className="cloud-detailes-wrapper">
        <div className="cloud-detailes-wrapper-headding">
          <div className="detailes-wrapper-headding-left">
            <motion.img
              src="/assets/img/DevSecOps.svg"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: -30, opacity: 0 },
              }}
              width="100%"
              alt=""
            />
          </div>
          <div className="detailes-wrapper-headding-right">
            <motion.p
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 30, opacity: 0 },
              }}
            >
              Shift left to gain competitive benefits by improving quality,
              speed, efficiency and compliance through our DevSecOps services.
            </motion.p>
            <div className="Cloude-services-wrapper">
              <motion.h3
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: 30, opacity: 0 },
                }}
              >
                How we help with DevSecOps services
              </motion.h3>
              <div className="gridsystem-2">
                <div className="Cloude-services-wrapper-inner">
                  <motion.p
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5 }}
                    variants={{
                      visible: { x: 0, opacity: 1 },
                      hidden: { x: 30, opacity: 0 },
                    }}
                  >
                    <b>Continuous Integration</b>
                  </motion.p>

                  <motion.p
                    className="devopsServiceline"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5 }}
                    variants={{
                      visible: { x: 0, opacity: 1 },
                      hidden: { x: 30, opacity: 0 },
                    }}
                  >
                    Continuous integration is naturally facilitated by our
                    DevOps approach. Every time code is checked into the Git
                    source code repository, it is automatically checked out and
                    built by a tool like TravisCI or Jenkins, and the team is
                    notified of any errors. TravisCI/Jenkins proceed to run a
                    full suite of automated tests, reporting any issues to the
                    team. CodeClimate (an open source static analysis tool)
                    automatically performs static code analysis, identifying
                    potential issues and ensuring test coverage.
                  </motion.p>
                </div>
                <div className="Cloude-services-wrapper-inner">
                  <motion.p
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5 }}
                    variants={{
                      visible: { x: 0, opacity: 1 },
                      hidden: { x: 30, opacity: 0 },
                    }}
                  >
                    <b>Continuous Delivery</b>
                  </motion.p>

                  <motion.p
                    className="devopsServiceline"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5 }}
                    variants={{
                      visible: { x: 0, opacity: 1 },
                      hidden: { x: 30, opacity: 0 },
                    }}
                  >
                    We build systems to support the automatic deployment of
                    software, which can be performed in a continuous manner by a
                    tool like TravisCI, on demand through Ansible, or via
                    command-line by the DevOps engineer. This is enabled by
                    cloud services to quickly stand up and tear down
                    infrastructure, Vagrant for quickly creating and destroying
                    environments, virtual environments based on Docker, and
                    automated configuration with Chef.
                  </motion.p>
                </div>
                <div className="Cloude-services-wrapper-inner">
                  <motion.p
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5 }}
                    variants={{
                      visible: { x: 0, opacity: 1 },
                      hidden: { x: 30, opacity: 0 },
                    }}
                  >
                    <b>Automated Testing</b>
                  </motion.p>

                  <motion.p
                    className="devopsServiceline"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5 }}
                    variants={{
                      visible: { x: 0, opacity: 1 },
                      hidden: { x: 30, opacity: 0 },
                    }}
                  >
                    We utilize a variety of open source frameworks to facilitate
                    a highly automated testing approach that supports the
                    execution of thousands of tests in minutes. These tests are
                    run automatically in the CI environment by tools such as
                    Travis CI or Jenkins upon every code check-in. This allows
                    us to automatically verify code module functionality and the
                    integration of these modules as it forms end-user
                    functionality. We also utilize Cucumber and Selenium to
                    automate testing user-facing functionality.
                  </motion.p>
                </div>
                <div className="Cloude-services-wrapper-inner">
                  <motion.p
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5 }}
                    variants={{
                      visible: { x: 0, opacity: 1 },
                      hidden: { x: 30, opacity: 0 },
                    }}
                  >
                    <b>Automated Monitoring</b>
                  </motion.p>

                  <motion.p
                    className="devopsServiceline"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5 }}
                    variants={{
                      visible: { x: 0, opacity: 1 },
                      hidden: { x: 30, opacity: 0 },
                    }}
                  >
                    By leveraging a range of automated monitoring
                    tools-including Nagios, Sensu, and Cloud watch-we are able
                    to check systems are up, disk and CPU usage is within normal
                    limits, all programs are patched for security
                    vulnerabilities, etc. We leverage both central monitoring
                    services, as well as distributed monitoring services
                    depending on the need for scalability and failure
                    resistance. This approach is extremely lightweight, add
                    minimum configuration and maintenance burden, and requires
                    little effort to apply to every system we implement.
                  </motion.p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Our_DevSecOps_services_wrapper">
          <motion.h3
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            variants={{
              visible: { x: 0, opacity: 1 },
              hidden: { x: 30, opacity: 0 },
            }}
          >
            Our DevSecOps services:
          </motion.h3>
          <div className="Our_DevSecOps_services_wrapper_inner">
            <div className="Our_DevSecOps_services_wrapper_inner_ul">
              <motion.ul
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: 30, opacity: 0 },
                }}
              >
                <li> DevOps as a Service</li>
                <li>DevOps Assessment</li>
                <li>Infrastructure as a Code</li>
                <li>Continuous Monitoring</li>
                <li>Containerisation/K8s</li>
                <li>Operational Management</li>
                <li>Release Management</li>
                <li>Security Management (DevSecOps)</li>
              </motion.ul>
            </div>
            <div className="Our_DevSecOps_services_wrapper_inner_ul">
              <motion.ul
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: 30, opacity: 0 },
                }}
              >
                <li>Documentation Support</li>
                <li>Testing and Health Monitoring</li>
                <li>Cloud Management</li>
                <li>Ongoing Performance</li>
                <li>Optimization Serverless Architecture</li>
                <li>Security & Compliance Automation</li>
                <li>Third-Party Software Integration</li>
              </motion.ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </motion.div>
  );
}

export default DevSecOps;
