import React from "react";
import "./css/Cloud.css";
import "./css/hero.css";
import { motion } from "framer-motion";
import Footer from "./footer";
function DataManagementAnalytics() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  return (
    <motion.div
      variants={container}
      className="Cloud-wrapper"
      initial="hidden"
      animate="show"
    >
      <div className="herowrapper">
        <div className="hero-main-partofthebox">
          <div className="hero-overlay"></div>
          <motion.img
            src="/assets/img/dataManegment.jpg"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            alt=""
          />
          <div className="headdingherosection">
            <motion.h2
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 30, opacity: 0 },
              }}
            >
              Data Management & Analytics
            </motion.h2>
          </div>
        </div>
      </div>
      <div className="cloud-detailes-wrapper">
        <div className="cloud-detailes-wrapper-headding">
          <div className="detailes-wrapper-headding-left">
            <motion.img
              src="/assets/img/DataManagement.svg"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: -30, opacity: 0 },
              }}
              width="100%"
              alt=""
            />
          </div>
          <div className="detailes-wrapper-headding-right">
            <motion.h3
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 30, opacity: 0 },
              }}
            >
              Transform data into real world actionable outcomes and insightful
              visualization.
            </motion.h3>

            <div className="Cloude-services-wrapper gridsystem-2">
              <div className="Cloude-services-wrapper-inner">
                <motion.h3
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { x: 0, opacity: 1 },
                    hidden: { x: 30, opacity: 0 },
                  }}
                >
                  Data Management
                </motion.h3>
                <motion.ul
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { x: 0, opacity: 1 },
                    hidden: { x: 30, opacity: 0 },
                  }}
                >
                  <li>
                    Plan and build manageable and scalable data solutions.
                  </li>
                  <li>
                    Accelerate data migrations with modernization and
                    automation.
                  </li>
                  <li>
                    Migrate seamlessly and securely structured, semi-structured
                    and unstructured enterprise data.
                  </li>
                  <li>Scalable data preparation for enterprise data lakes</li>
                  <li>
                    Adhere to data security and mitigate risks with data
                    governance
                  </li>
                </motion.ul>
              </div>
              <div className="Cloude-services-wrapper-inner">
                <motion.h3
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { x: 0, opacity: 1 },
                    hidden: { x: 30, opacity: 0 },
                  }}
                >
                  Visualization
                </motion.h3>
                <motion.ul
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { x: 0, opacity: 1 },
                    hidden: { x: 30, opacity: 0 },
                  }}
                >
                  <li>
                    Transform overwhelming and complex disparate data sets to
                    develop powerful data models to uncover relationships,
                    trends and opportunities.
                  </li>
                  <li>
                    Bring data to life with analytics and visualization to make
                    better and informed business strategy and decisions.
                  </li>
                </motion.ul>
              </div>
              <div className="Cloude-services-wrapper-inner">
                <motion.h3
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { x: 0, opacity: 1 },
                    hidden: { x: 30, opacity: 0 },
                  }}
                >
                  AI & Machine Learning
                </motion.h3>
                <motion.ul
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { x: 0, opacity: 1 },
                    hidden: { x: 30, opacity: 0 },
                  }}
                >
                  <li>
                    Impactful decision making with Artificial Intelligence and
                    Machine Learning Manage project effectively with Lean-Agile
                    mindset and supported by human centered design.
                  </li>
                  <li>
                    We have domain knowledge and the experience solving problems
                    with machine learning (ML), deep learning (DL), robotic
                    process automation (RPA) and other AI technology.
                  </li>
                  <li>
                    Contact our team to learn more about how we can help you put
                    together an impactful data solution.
                  </li>
                </motion.ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </motion.div>
  );
}

export default DataManagementAnalytics;
