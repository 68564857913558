import React from "react";
import "./css/hero.css";
import { motion } from "framer-motion";
function Recapinfo() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const bottomtotop = {
    hidden: { y: 30, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        ease: [0.6, 0.99, 0.99, 0.95],
        duration: 0.5,
      },
    },
  };
  return (
    <motion.div
      className="recapinfo-wrapper"
      variants={container}
      initial="hidden"
      animate="show"
    >
      <div className="recapinfo-wrapper-inner">
        <motion.p variants={bottomtotop}>
          CloudAvent is a technology firm that solves complex challenges with
          innovation and simple scalable solutions for businesses and the
          Federal Government through modernization and digital transformation
          solutions with Modern Delivery Management Services, Cloud, DevSecOps,
          Data and AI & ML.
        </motion.p>
      </div>
    </motion.div>
  );
}

export default Recapinfo;
