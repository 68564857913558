import React from "react";
import "./css/WhatWeOffer.css";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import Footer from "./footer";

import cloudWeOffer from "../images/cloud-weOffer.svg";
import MicroservicesWeOffer from "../images/Microservices-weOffer.svg";
import UXWeOffer from "../images/UX-weOffer.svg";
import devsecWeOffer from "../images/devsec-weOffer.svg";
import governanceWeOffer from "../images/governance-weOffer.svg";
import enterpriceWeOffer from "../images/enterprice-weOffer.svg";
import migrationWeOffer from "../images/migration-weOffer.svg";

function WhatWeOffer() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const Zoomin = {
    hidden: { scale: 0, opacity: 0 },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 0.5,
      },
    },
  };
  return (
    <motion.div
      variants={container}
      className="Wrapper mian-we-are-page"
      initial="hidden"
      animate="show"
    >
      <div className="weoffercontainer">
        <div className="contact-wraperforrheading">
          <div className="hero-main-partofthebox">
            {" "}
            <h1>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                Get
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.02 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                to
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.04 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                know
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.06 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                about
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.08 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                our
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.1 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                provided
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.12 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                services
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.14 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                and
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.16 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                offerings
              </motion.span>
            </h1>
            <motion.p
              style={{ textAlign: "center" }}
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.18 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              What we offer
            </motion.p>
            <motion.div
              className="hamburger-links-box"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 20, opacity: 0 },
              }}
            >
              <NavLink to="/">Home</NavLink>&nbsp;|&nbsp;<p>What we Offer</p>
            </motion.div>
          </div>
        </div>
        <div className="weoffer-main-partofthebox weoffer-data-mianpage">
          <div className="weoffer-maincontainer">
            <div className="heading-box">
              <motion.h2
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: 20, opacity: 0 },
                }}
              >
                Modern Application
                <br />
                Delivery{" "}
              </motion.h2>
              <motion.p
                className="overlay-nuberinf"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.02 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 },
                }}
              >
                01
              </motion.p>
            </div>
            <div className="detailes-box whatweoffer-services">
              <motion.div
                className="whatweoffer-services-inner-box"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.04 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                <div
                  className="services-img-box-outer"
                  style={{ padding: "0 10px" }}
                >
                  <div
                    style={{
                      background: "rgba(237, 27, 44, 0.7)",
                      borderRadius: "50rem",
                      border: "2px solid rgb(237, 27, 44)",
                      padding: "0.5rem",
                    }}
                  >
                    <div
                      className="services-img-box"
                      style={{ background: "rgba(237, 27, 44, 0.5)" }}
                    >
                      <img src={cloudWeOffer} alt="" />
                    </div>
                  </div>
                </div>
                <div className="services-detailes-box">
                  <h3>Cloud Services</h3>
                  <p>
                    <span style={{ color: "#ffcc31", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Multi cloud architecture strategy
                  </p>
                  <p>
                    <span style={{ color: "#ffcc31", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Cloud migration at scale
                  </p>
                  <p>
                    <span style={{ color: "#ffcc31", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Hybrid cloud architecture & design
                  </p>
                  <p>
                    <span style={{ color: "#ffcc31", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Cloud Financial Operations.
                  </p>
                </div>
              </motion.div>
              <motion.div
                className="whatweoffer-services-inner-box"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.06 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                <div
                  className="services-img-box-outer"
                  style={{ padding: "0 10px" }}
                >
                  <div
                    style={{
                      background: "rgba(255, 203, 49, 0.7)",
                      borderRadius: "50rem",
                      border: "2px solid rgb(255, 203, 49)",
                      padding: "0.5rem",
                    }}
                  >
                    <div
                      className="services-img-box"
                      style={{ background: "rgba(255, 203, 49, 0.5)" }}
                    >
                      <img src={MicroservicesWeOffer} alt="" />
                    </div>
                  </div>
                </div>
                <div className="services-detailes-box">
                  <h3>Microservices </h3>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Advanced Serverless projects
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Advanced GraphQL API aggregation
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Enterprise scalable API platform
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Enterprise scale service mesh
                  </p>
                </div>
              </motion.div>

              <motion.div
                className="whatweoffer-services-inner-box"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.08 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                <div
                  className="services-img-box-outer"
                  style={{ padding: "0 10px" }}
                >
                  <div
                    style={{
                      background: "rgba(255, 203, 49, 0.7)",
                      borderRadius: "50rem",
                      border: "2px solid rgb(255, 203, 49)",
                      padding: "0.5rem",
                    }}
                  >
                    <div
                      className="services-img-box"
                      style={{ background: "rgba(255, 203, 49, 0.5)" }}
                    >
                      <img src={UXWeOffer} alt="" />
                    </div>
                  </div>
                </div>
                <div className="services-detailes-box">
                  <h3>Human Centric UI/UX </h3>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Enterprise UI Platform with Web Components
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Technology agnostic Autonomous Micro Front Ends
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Responsive, omni channel Front End
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Low code platforms for Rapid Prototyping\MVP.
                  </p>
                </div>
              </motion.div>
              <motion.div
                className="whatweoffer-services-inner-box"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.1 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                <div
                  className="services-img-box-outer"
                  style={{ padding: "0 10px" }}
                >
                  <div
                    style={{
                      background: "rgba(237, 27, 44, 0.7)",
                      borderRadius: "50rem",
                      border: "2px solid rgb(237, 27, 44)",
                      padding: "0.5rem",
                    }}
                  >
                    <div
                      className="services-img-box"
                      style={{ background: "rgba(237, 27, 44, 0.5)" }}
                    >
                      <img src={devsecWeOffer} alt="" />
                    </div>
                  </div>
                </div>
                <div className="services-detailes-box">
                  <h3>DevSec OPS</h3>
                  <p>
                    <span style={{ color: "#ffcc31", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Multi cloud Infrastructure as Code
                  </p>
                  <p>
                    <span style={{ color: "#ffcc31", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Enterprise scale modular pipeline
                  </p>
                  <p>
                    <span style={{ color: "#ffcc31", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    No touch controls automated pipeline
                  </p>
                  <p>
                    <span style={{ color: "#ffcc31", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Side Car monitoring dependency Injection
                  </p>
                </div>
              </motion.div>
            </div>
          </div>
          <div className="weoffer-maincontainer-2">
            <div className="detailes-box whatweoffer-services">
              <motion.div
                className="whatweoffer-services-inner-box"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                <div
                  className="services-img-box-outer"
                  style={{ padding: "0 10px" }}
                >
                  <div
                    style={{
                      background: "rgba(237, 27, 44, 0.7)",
                      borderRadius: "50rem",
                      border: "2px solid rgb(237, 27, 44)",
                      padding: "0.5rem",
                    }}
                  >
                    <div
                      className="services-img-box"
                      style={{ background: "rgba(237, 27, 44, 0.5)" }}
                    >
                      <img src={governanceWeOffer} alt="" />
                    </div>
                  </div>
                </div>
                <div className="services-detailes-box">
                  <h3>Data Governance and Architecture</h3>
                  <p>
                    <span style={{ color: "#ffcc31", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Modern Toolset for hybrid environments
                  </p>
                  <p>
                    <span style={{ color: "#ffcc31", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    State of the art governance models
                  </p>
                  <p>
                    <span style={{ color: "#ffcc31", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Cutting edge data quality tools
                  </p>
                  <p>
                    <span style={{ color: "#ffcc31", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    One stop Data cataloging, lineage & Integration
                  </p>
                </div>
              </motion.div>
              <motion.div
                className="whatweoffer-services-inner-box"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.02 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                <div
                  className="services-img-box-outer"
                  style={{ padding: "0 10px" }}
                >
                  <div
                    style={{
                      background: "rgba(255, 203, 49, 0.7)",
                      borderRadius: "50rem",
                      border: "2px solid rgb(255, 203, 49)",
                      padding: "0.5rem",
                    }}
                  >
                    <div
                      className="services-img-box"
                      style={{ background: "rgba(255, 203, 49, 0.5)" }}
                    >
                      <img src={enterpriceWeOffer} alt="" />
                    </div>
                  </div>
                </div>
                <div className="services-detailes-box">
                  <h3>Enterprise Data Lake</h3>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Design for Petabytes of Data Ingestion
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Design to ingest 155B+ events a day
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Realtime serverless fast data Ingestion
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Data Processing Orchestration
                  </p>
                </div>
              </motion.div>

              <motion.div
                className="whatweoffer-services-inner-box"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.04 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                <div
                  className="services-img-box-outer"
                  style={{ padding: "0 10px" }}
                >
                  <div
                    style={{
                      background: "rgba(255, 203, 49, 0.7)",
                      borderRadius: "50rem",
                      border: "2px solid rgb(255, 203, 49)",
                      padding: "0.5rem",
                    }}
                  >
                    <div
                      className="services-img-box"
                      style={{ background: "rgba(255, 203, 49, 0.5)" }}
                    >
                      <img src={migrationWeOffer} alt="" />
                    </div>
                  </div>
                </div>
                <div className="services-detailes-box">
                  <h3>Data Migration and Data Analytics</h3>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Advanced DB Migration engineering
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    SQL database migration at scale
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Heterogeneous Data Migrations
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Cloud Native Data Migration
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Cloud Native and Hybrid Data Lake patterns
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Data Visualization with Fast BI Engines
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Encryption at REST and Transit
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Masking for PII\NPI data for compliance
                  </p>
                </div>
              </motion.div>
            </div>
            <div className="heading-box">
              <motion.h2
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.06 }}
                variants={{
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -20, opacity: 0 },
                }}
              >
                Data Services and <br /> Management
              </motion.h2>
              <motion.p
                className="overlay-nuberinf"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.08 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 },
                }}
              >
                02
              </motion.p>
            </div>
          </div>
          <div className="weoffer-maincontainer">
            <div className="heading-box">
              <motion.h2
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: 20, opacity: 0 },
                }}
              >
                AI and ML as a <br />
                Service
              </motion.h2>
              <motion.p
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.02 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 },
                }}
                className="overlay-nuberinf"
              >
                03
              </motion.p>
            </div>
            <div className="detailes-box whatweoffer-services">
              <motion.div
                className="whatweoffer-services-inner-box"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.04 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                <div
                  className="services-img-box-outer"
                  style={{ padding: "0 10px" }}
                >
                  <div
                    style={{
                      background: "rgba(237, 27, 44, 0.7)",
                      borderRadius: "50rem",
                      border: "2px solid rgb(237, 27, 44)",
                      padding: "0.5rem",
                    }}
                  >
                    <div
                      className="services-img-box"
                      style={{ background: "rgba(237, 27, 44, 0.5)" }}
                    >
                      <img src={cloudWeOffer} alt="" />
                    </div>
                  </div>
                </div>
                <div className="services-detailes-box">
                  <h3>Data Science and Machine Learning </h3>
                  <p>
                    <span style={{ color: "#ffcc31", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Scalable Data science Infrastructure
                  </p>
                  <p>
                    <span style={{ color: "#ffcc31", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    State of the art Jupyter notebooks
                  </p>
                  <p>
                    <span style={{ color: "#ffcc31", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Multi source data workloads
                  </p>
                  <p>
                    <span style={{ color: "#ffcc31", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Predictive Analytics
                  </p>
                  <p>
                    <span style={{ color: "#ffcc31", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Sentiment Analysis
                  </p>
                  <p>
                    <span style={{ color: "#ffcc31", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Machine Learning @ Scale
                  </p>
                  <p>
                    <span style={{ color: "#ffcc31", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Modern cloud native and cloud agnostic Tool chain
                  </p>
                </div>
              </motion.div>
              <motion.div
                className="whatweoffer-services-inner-box"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.06 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                <div
                  className="services-img-box-outer"
                  style={{ padding: "0 10px" }}
                >
                  <div
                    style={{
                      background: "rgba(255, 203, 49, 0.7)",
                      borderRadius: "50rem",
                      border: "2px solid rgb(255, 203, 49)",
                      padding: "0.5rem",
                    }}
                  >
                    <div
                      className="services-img-box"
                      style={{ background: "rgba(255, 203, 49, 0.5)" }}
                    >
                      <img src={MicroservicesWeOffer} alt="" />
                    </div>
                  </div>
                </div>
                <div className="services-detailes-box">
                  <h3>AI and ML OPS</h3>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Automatic Text redaction and Forms Processing
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    FAST AI, GPT-*, pytorch, Tensorflow Implementation
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Deep Learning, Genetic Algorithms
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    AI @ Edge, chatbots
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Advanced tool chain for CI\CD\CT
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Enterprise scale ML pipeline
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Anomaly detection with configurable threshold
                  </p>
                  <p>
                    <span style={{ color: "#ed1b2e", fontSize: "20px" }}>
                      ▸
                    </span>{" "}
                    Scalable base infrastructure
                  </p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="box-for-background">
          <div className="box-for-background-inner">
            <div className="box-for-background-weoffer-circule"></div>
            <div className="box-for-background-weoffer-circule-1"></div>
            <div className="box-for-background-weoffer-circule-2"></div>
            <div className="box-for-background-weoffer-circule-4"></div>
          </div>
        </div>
      </div>
      <Footer />
    </motion.div>
  );
}

export default WhatWeOffer;
