import React from "react";
import "./css/hero.css";
import { motion } from "framer-motion";
import imageSvg from "../images/image-svg.svg";
import musicSvg from "../images/music-svg.svg";
import cloudeSvg from "../images/cloude-svg.svg";
import lockSvg from "../images/lock-svg.svg";
import storageherosection1 from "../images/storageherosection1.svg";
import { NavLink } from "react-router-dom";
function Herosection() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  return (
    <motion.div
      variants={container}
      className="manntopwrapper"
      initial="hidden"
      animate="show"
    >
      <div className="herowrapper">
        <div className="hero-main-partofthebox">
          <div className="hero-overlay"></div>
          <motion.video
            className="videoTag"
            width="100%"
            autoPlay
            loop
            muted
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
          >
            <source src="/assets/img/smallcloudehome.mp4" type="video/mp4" />
          </motion.video>
          {/*           <img src="/assets/img/cloudavant.jpg" alt="" />
           */}{" "}
          <div className="headdingherosection">
            <motion.h2
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 30, opacity: 0 },
              }}
            >
              Outcome Driven Solutions
            </motion.h2>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Herosection;
