import React, { useRef } from "react";
import "./css/Career.css";
import Footer from "./footer";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import { NavLink } from "react-router-dom";
import creer1 from "../images/creer1.png";
import creer2 from "../images/creer2.png";
import creer3 from "../images/creer3.png";
function Career() {
  const form = useRef();

  const sendCareerEmail = (e) => {
    if (
      document.getElementById("fname").value != "" &&
      document.getElementById("Surename").value != "" &&
      document.getElementById("email").value != "" &&
      document.getElementById("Phone").value != "" &&
      document.getElementById("subject").value != "" &&
      document.getElementById("massage").value != ""
    ) {
      document.getElementById("careerloddingofbutton").style.display = "block";
      document.getElementById("sendcareer").style.display = "none";
      e.preventDefault();
      {
        {
          /* this email is setup on veersanja@gmail.com on https://dashboard.emailjs.com/*/
        }
      }
      emailjs
        .sendForm(
          "service_vnr4g8e",
          "template_7b90hpt",
          form.current,
          "oixiOWmtnBNwywjiu"
        )
        .then(
          (result) => {
            console.log(result.text);
            document.getElementById("fname").value = "";
            document.getElementById("Surename").value = "";
            document.getElementById("email").value = "";
            document.getElementById("Phone").value = "";
            document.getElementById("subject").value = "";
            document.getElementById("massage").value = "";

            document.getElementById("careerunsuccessmsg").style.display =
              "none";
            document.getElementById("careeremptyfuild").style.display = "none";
            document.getElementById("careertsuceesmsg").style.display = "block";
            document.getElementById("careerloddingofbutton").style.display =
              "none";
            document.getElementById("sendcareer").style.display = "block";
          },
          (error) => {
            console.log(error.text);
            document.getElementById("careertsuceesmsg").style.display = "none";
            document.getElementById("careeremptyfuild").style.display = "none";
            document.getElementById("careerunsuccessmsg").style.display =
              "block";
            document.getElementById("careerloddingofbutton").style.display =
              "none";
            document.getElementById("sendcareer").style.display = "block";
          }
        );
    } else {
      e.preventDefault();
      document.getElementById("careertsuceesmsg").style.display = "none";
      document.getElementById("careerunsuccessmsg").style.display = "none";
      document.getElementById("careeremptyfuild").style.display = "block";
      return false;
    }
  };
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const toptobottom = {
    hidden: { y: 30, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        ease: [0.6, 0.99, 0.99, 0.95],
        duration: 0.5,
      },
    },
  };
  const fadein = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        ease: [0.6, 0.99, 0.99, 0.95],
        duration: 2,
      },
    },
  };
  return (
    <div>
      <motion.div
        className="homepagecontainer"
        style={{ fontSize: "18px" }}
        initial="hidden"
        animate="show"
        variants={container}
      >
        <div className="Career_form_wrapper">
          <div className="contact-wraperforrheading">
            <div className="hero-main-partofthebox"> </div>
          </div>
          <motion.h1
            className="contact-withus"
            style={{ color: "#fff" }}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.22 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 20, opacity: 0 },
            }}
          >
            Look at our current openings
          </motion.h1>
          <div className="opning-box-main">
            <motion.div
              className="current-openings"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 20, opacity: 0 },
              }}
            >
              <div className="current-opning-image">
                <img src={creer1} width="100%" alt="" />
              </div>
              <h4>Lorem Ipsum is simply</h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </p>
            </motion.div>
            <motion.div
              className="current-openings"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.03 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 20, opacity: 0 },
              }}
            >
              <div className="current-opning-image">
                <img src={creer2} width="100%" alt="" />
              </div>
              <h4>Lorem Ipsum is simply</h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </p>
            </motion.div>
            <motion.div
              className="current-openings"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.06 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 20, opacity: 0 },
              }}
            >
              <div className="current-opning-image">
                <img src={creer3} width="100%" alt="" />
              </div>
              <h4>Lorem Ipsum is simply</h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </p>
            </motion.div>
          </div>
          <motion.h1
            className="contact-withus"
            style={{ color: "#fff" }}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.16 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 20, opacity: 0 },
            }}
          >
            Join with us
          </motion.h1>
          <div className="Career_form_container">
            <form ref={form}>
              <div className="career_form_sidebyside">
                <motion.input
                  type="text"
                  name="fname"
                  id="fname"
                  placeholder="*first Name"
                  variants={toptobottom}
                />
                <motion.input
                  type="text"
                  name="Surename"
                  id="Surename"
                  placeholder="*Surename"
                  variants={toptobottom}
                />
              </div>
              <div className="career_form_sidebyside">
                <motion.input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="*E-mail"
                  variants={toptobottom}
                />
                <motion.input
                  type="text"
                  name="Phone"
                  id="Phone"
                  placeholder="*Phone"
                  variants={toptobottom}
                />
              </div>
              <div className="last_form">
                <motion.input
                  type="text"
                  name="subject"
                  id="subject"
                  placeholder="*Subject"
                  variants={toptobottom}
                />
                <motion.label
                  htmlFor="massage"
                  variants={toptobottom}
                  style={{ color: "#777" }}
                >
                  *Massage
                </motion.label>
                <motion.textarea
                  name="massage"
                  id="massage"
                  rows="10"
                  variants={toptobottom}
                ></motion.textarea>
                <div className="careerbuttunncommentcontainer">
                  <motion.button
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.1 }}
                    variants={{
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: 10, opacity: 0 },
                    }}
                    type="submit"
                  >
                    <span className="sendcareer" id="sendcareer">
                      Send
                    </span>
                    <i
                      className="fa fa-spinner careerloddingofbutton"
                      id="careerloddingofbutton"
                    ></i>
                  </motion.button>
                  <div style={{ width: "100%", marginLeft: "0.5rem" }}>
                    <p className="careertsuceesmsg" id="careertsuceesmsg">
                      Your Message is sent Successfully
                    </p>
                    <p className="careerunsuccessmsg" id="careerunsuccessmsg">
                      Something went wrong, your message isn't sent. Try
                      again!!!
                    </p>
                    <p className="careerunsuccessmsg" id="careeremptyfuild">
                      You have empty fields, fill them first.
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* <div className="box-for-background">
          <div className="box-for-background-inner">
            <div className="box-for-background-carrer-circule-1"></div>
            <div className="box-for-background-carrer-circule-2"></div>
            <div className="box-for-background-carrer-circule-3"></div>
          </div>
        </div> */}
      </motion.div>
      <Footer />
    </div>
  );
}

export default Career;
