import React, { useState } from "react";
import "./css/navbar.css";
import cloudlog from "../images/CloudAvent.svg";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
function Navbar() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const bottomtotop = {
    hidden: { y: -30, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        ease: [0.6, 0.99, 0.99, 0.95],
        duration: 0.5,
      },
    },
  };
  const zoom = {
    hidden: { opacity: 0, scale: 0.5 },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 2,
      },
    },
  };
  const [Click, setClick] = useState(false);

  const handelClick = () => setClick(!Click);

  return (
    <motion.div variants={container} initial="hidden" animate="show">
      <motion.div className="main_wrapper_nev" variants={bottomtotop}>
        <div
          className={
            Click ? "sm_nevlinks_container active" : "sm_nevlinks_container "
          }
        >
          <ul className="sm_navlist">
            <li className="sm_nevlinks">
              <NavLink
                to="/"
                onClick={handelClick}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                home
              </NavLink>
            </li>
            <li className="sm_nevlinks">
              <HashLink
                to="/#AboutUs"
                onClick={handelClick}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                About
              </HashLink>
            </li>
            <li className="sm_nevlinks">
              <HashLink
                to="/#Capabilities"
                onClick={handelClick}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Capabilities
              </HashLink>
            </li>

            <li className="sm_nevlinks">
              <NavLink
                to="/CaseStudies"
                onClick={handelClick}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Case Studies
              </NavLink>
            </li>
            <li className="sm_nevlinks">
              <NavLink
                to="/Career"
                onClick={handelClick}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                career
              </NavLink>
            </li>
            <li className="sm_nevlinks">
              <NavLink
                to="/Contect"
                onClick={handelClick}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <NavLink to="/">
            <img
              src="/assets/img/CloudAventA.png"
              style={{ width: "117px" }}
              alt=""
            />
          </NavLink>
        </div>
        <div style={{ zIndex: "9" }}>
          <div className="hamburdermenu" onClick={handelClick}>
            {Click ? (
              <img src="/assets/img/hemburgerx.svg" alt="" />
            ) : (
              <img src="/assets/img/hemburger.svg" alt="" />
            )}{" "}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default Navbar;
