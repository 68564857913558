import React from "react";
import "./css/footer.css";
import { NavLink } from "react-router-dom";
import facebook from "../images/facebook.svg";
import instagram from "../images/instagram.svg";
import twitter from "../images/twitter.svg";
function Footer() {
  return (
    <>
      <div className="footer_wrapper">
        <div className="footer_inner_main">
          <div className="footer_topbox">
            <div className="footer_head_discreaption">
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src="/assets/img/CloudAventF.png"
                  className="footer-logo"
                  width="100%"
                  alt=""
                />
              </div>
            </div>
            <div className="footer_head_discreaption">
              <div className="footer_head_discreaption-inner-detailes">
                <div className="footer_detailes_box ">
                  <div className="footer_inner_detailes">
                    <h3>CloudAvent HQ</h3>
                    <p>
                      44679 Endicott Drive
                      <br />
                      Ashburn, VA 20147
                    </p>
                    {/*   <a href="tel:7039350900"> 703-935-0900</a>
                  <a href="mailto:Inquiries@cloudavent.com">
                    Inquiries@cloudavent.com
                  </a> */}
                  </div>
                  <div className="footer_inner_detailes">
                    <h3>Contact Us</h3>
                    {/*  <a href="tel:7039350900"> 703-935-0900</a> */}
                    <a href="mailto:info@cloudavent.com">info@cloudavent.com</a>
                  </div>
                  <div className="footer_inner_detailes">
                    <h3>Privacy</h3>
                    {/*  <a href="tel:7039350900"> 703-935-0900</a> */}
                    <a href="">Privacy Policy</a>
                    <a href="">Cookie Policy</a>
                  </div>
                </div>
                <hr className="hrtage" />
                <div className="bottom_footer">
                  <div
                    className="bottom-footer-policy"
                    style={{ display: "flex" }}
                  >
                    <p>
                      {" "}
                      Copyright {new Date().getFullYear()} Cloudavent LLC |
                      All Rights Reserved
                    </p>
                  </div>
                  <div className="social-media-links">
                    <a href="#" target="_blank">
                      <img
                        src="/assets/img/linkedin.svg"
                        width="25"
                        target="_blank"
                      />
                    </a>
                    <a href="https://twitter.com/cloudavent" target="_blank">
                      <img
                        src="/assets/img/twitter.svg"
                        width="25"
                        target="_blank"
                      />
                    </a>
                    <a
                      href="https://www.facebook.com/profile.php?id=100090186998519"
                      target="_blank"
                    >
                      <img src="/assets/img/facebook.svg" width="25" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
