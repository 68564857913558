import React from "react";
import "./css/CaseStudies.css";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import Footer from "./footer";
import agileTransformation from "../images/agile-transformation.jpg";
import MordenUiux from "../images/Morden-uiux.jpg";
function CaseStudies() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  return (
    <motion.div
      variants={container}
      className="Wrapper"
      initial="hidden"
      animate="show"
    >
      <div className="CaseStudiescontainer">
        <div className="contact-wraperforrheading">
          <div className="hero-main-partofthebox">
            {" "}
            <h1>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                Read
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.02 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                case
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.04 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                studies
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.06 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                around
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.08 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                the
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.1 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                services
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.12 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                we
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.14 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                provide
              </motion.span>
            </h1>
            <motion.p
              style={{ textAlign: "center" }}
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.16 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 20, opacity: 0 },
              }}
            >
              Case Studies
            </motion.p>
            <motion.div
              className="hamburger-links-box"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.18 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 20, opacity: 0 },
              }}
            >
              <NavLink to="/">Home</NavLink>&nbsp;|&nbsp;<p>Case Studies</p>
            </motion.div>
          </div>
        </div>
        <div className="CaseStudies-main-partofthebox">
          <div
            className="CaseStudies-maincontainer-main"
            style={{ margin: "0%" }}
          >
            <div
              className="detailes-box CaseStudies-box-main"
              style={{ padding: "0" }}
            >
              <NavLink
                to="/CaseStudies/article"
                style={{ textDecoration: "none" }}
              >
                <motion.div
                  className="main-casebox"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 20, opacity: 0 },
                  }}
                >
                  <img src={agileTransformation} />
                  <h4>agile transformation</h4>
                  <p>
                    90% reduction in production deployment time and increase in
                    deployment frequency of financial application for a leading
                    mortgage firm.
                  </p>
                </motion.div>
              </NavLink>
              <NavLink
                to="/CaseStudies/article"
                style={{ textDecoration: "none" }}
              >
                <motion.div
                  className="main-casebox"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.12 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 20, opacity: 0 },
                  }}
                >
                  <img src={MordenUiux} />
                  <h4>Modern Ui Ux</h4>
                  <p>
                    Modernization of a monolithic application delivers superior
                    user experience and 5x improvement in productivity.
                  </p>
                </motion.div>
              </NavLink>
              <NavLink
                to="/CaseStudies/article"
                style={{ textDecoration: "none" }}
              >
                <motion.div
                  className="main-casebox"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 20, opacity: 0 },
                  }}
                >
                  <img src={agileTransformation} />
                  <h4>agile transformation</h4>
                  <p>
                    90% reduction in production deployment time and increase in
                    deployment frequency of financial application for a leading
                    mortgage firm.
                  </p>
                </motion.div>
              </NavLink>
              <NavLink
                to="/CaseStudies/article"
                style={{ textDecoration: "none" }}
              >
                <motion.div
                  className="main-casebox"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.12 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 20, opacity: 0 },
                  }}
                >
                  <img src={MordenUiux} />
                  <h4>Modern Ui Ux</h4>
                  <p>
                    Modernization of a monolithic application delivers superior
                    user experience and 5x improvement in productivity.
                  </p>
                </motion.div>
              </NavLink>
              <NavLink
                to="/CaseStudies/article"
                style={{ textDecoration: "none" }}
              >
                <motion.div
                  className="main-casebox"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 20, opacity: 0 },
                  }}
                >
                  <img src={agileTransformation} alt="" />
                  <h4>agile transformation</h4>
                  <p>
                    90% reduction in production deployment time and increase in
                    deployment frequency of financial application for a leading
                    mortgage firm.
                  </p>
                </motion.div>
              </NavLink>
              <NavLink
                to="/CaseStudies/article"
                style={{ textDecoration: "none" }}
              >
                <motion.div
                  className="main-casebox"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.12 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 20, opacity: 0 },
                  }}
                >
                  <img src={MordenUiux} />
                  <h4>Modern Ui Ux</h4>
                  <p>
                    Modernization of a monolithic application delivers superior
                    user experience and 5x improvement in productivity.
                  </p>
                </motion.div>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="box-for-background">
          <div className="box-for-background-inner">
            <div className="box-for-background-carrer-circule-1"></div>
            <div className="box-for-background-carrer-circule-2"></div>
            <div className="box-for-background-carrer-circule-3"></div>
          </div>
        </div>
      </div>
      <Footer />
    </motion.div>
  );
}

export default CaseStudies;
