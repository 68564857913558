import React from "react";
import "./css/CaseStudies.css";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import Footer from "./footer";
import agileTransformation from "../images/agile-transformation.jpg";
function CaseStudiesArticale() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  return (
    <motion.div
      variants={container}
      className="Wrapper"
      initial="hidden"
      animate="show"
    >
      <div className="CaseStudiescontainer">
        <div className="contact-wraperforrheading">
          <div className="hero-main-partofthebox">
            {" "}
            <h1>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                Agile
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.02 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                transformation
              </motion.span>
            </h1>
            <motion.p
              style={{ textAlign: "center", padding: "0 15vw" }}
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.16 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 20, opacity: 0 },
              }}
            >
              90% reduction in production deployment time and increase in
              deployment frequency of financial application for a leading
              mortgage firm.
            </motion.p>
            <motion.div
              className="hamburger-links-box"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.18 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 20, opacity: 0 },
              }}
            >
              <NavLink to="/">Home</NavLink>&nbsp;|&nbsp;
              <NavLink to="/CaseStudies">Case Studies</NavLink>&nbsp;|&nbsp;
              <p>Article</p>
            </motion.div>
          </div>
        </div>
        <div className="CaseStudies-main-partofthebox">
          <div
            className="CaseStudies-maincontainer-main"
            style={{ margin: "0%" }}
          >
            <div
              className="detailes-box CaseStudies-box-articale"
              style={{ padding: "0" }}
            >
              <motion.div
                className="main-casebox"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.1 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                <img src={agileTransformation} alt="" />
              </motion.div>
              <p style={{ textAlign: "center" }}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
              <p style={{ textAlign: "center" }}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
              <p style={{ textAlign: "center" }}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has of
                type and scrambled it to make a type specimen book. It has of
                type and scrambled it to make a type specimen book. It has of
                type and scrambled it to make a type specimen book. It has of
                type and scrambled it to make a type specimen book. It has of
                type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
              <p style={{ textAlign: "center" }}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </p>
            </div>
          </div>
        </div>
        <div className="box-for-background">
          <div className="box-for-background-inner">
            <div className="box-for-background-carrer-circule-1"></div>
            <div className="box-for-background-carrer-circule-2"></div>
            <div className="box-for-background-carrer-circule-3"></div>
          </div>
        </div>
      </div>
      <Footer />
    </motion.div>
  );
}

export default CaseStudiesArticale;
