import React from "react";
import "./css/capabilities.css";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
function Capabilitys() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  return (
    <motion.div
      id="Capabilities"
      variants={container}
      initial="hidden"
      animate="show"
      className="capabilities-wrapper"
    >
      <div className="capabilities-wrapper-inner">
        <div className="capabilities-left-headding">
          <div className="left-headding-container">
            <motion.p
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
            >
              Capabilities
            </motion.p>
          </div>
        </div>
        <div className="capabilities-right-detailes">
          <div className="capabilities-right-detailes-inner">
            <div className="detailes-inner-container">
              <motion.h3
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 30, opacity: 0 },
                }}
              >
                Modern Delivery
              </motion.h3>
              <motion.img
                src="/assets/img/ModernDelivery.svg"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 30, opacity: 0 },
                }}
              />
              <motion.p
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 30, opacity: 0 },
                }}
              >
                Modernize platforms and digital capabilities with incremental,
                iterative, and lean design process to create a product that
                meets both stakeholder and user requirements.
              </motion.p>
              <motion.div
                className="link-container"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 30, opacity: 0 },
                }}
              >
                <Link to="/capabilities/ModernDelivery">Learn More</Link>
              </motion.div>
            </div>
          </div>
          <div className="capabilities-right-detailes-inner">
            <div className="detailes-inner-container">
              <motion.h3
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 30, opacity: 0 },
                }}
              >
                Cloud
              </motion.h3>
              <motion.img
                src="/assets/img/Cloud.svg"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 30, opacity: 0 },
                }}
              />
              <motion.p
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 30, opacity: 0 },
                }}
              >
                Secure and scalable cloud migration and modernization solutions
                for data anywhere, anytime, and on any device.
              </motion.p>
              <motion.div
                className="link-container"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 30, opacity: 0 },
                }}
              >
                <Link to="/capabilities/cloud">Learn More</Link>
              </motion.div>
            </div>
          </div>
          <div className="capabilities-right-detailes-inner">
            <div className="detailes-inner-container">
              <motion.h3
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 30, opacity: 0 },
                }}
              >
                Data Management & Analytics
              </motion.h3>
              <motion.img
                src="/assets/img/DataManagement.svg"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 30, opacity: 0 },
                }}
              />
              <motion.p
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 30, opacity: 0 },
                }}
              >
                Manage data to uncover complex relationships, transform data to
                analytics and visualizations to make informed business strategy
                and decisions
              </motion.p>
              <motion.div
                className="link-container"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 30, opacity: 0 },
                }}
              >
                <Link to="/capabilities/data">Learn More</Link>
              </motion.div>
            </div>
          </div>
          <div className="capabilities-right-detailes-inner">
            <div className="detailes-inner-container">
              <motion.h3
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 30, opacity: 0 },
                }}
              >
                DevSecOps
              </motion.h3>
              <motion.img
                src="/assets/img/DevSecOps.svg"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 30, opacity: 0 },
                }}
              />
              <motion.p
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 30, opacity: 0 },
                }}
              >
                Shift left to achieve transformation with speed, agility and
                compliance.
              </motion.p>
              <motion.div
                className="link-container"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 30, opacity: 0 },
                }}
              >
                <Link to="/capabilities/devSecOps">Learn More</Link>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Capabilitys;
