import React from "react";
import "./css/Cloud.css";
import "./css/hero.css";
import { motion } from "framer-motion";
import Footer from "./footer";
function ModernDelivery() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  return (
    <motion.div
      variants={container}
      className="Cloud-wrapper"
      initial="hidden"
      animate="show"
    >
      <div className="herowrapper">
        <div className="hero-main-partofthebox">
          <div className="hero-overlay"></div>
          <img src="/assets/img/delivery.jpg" alt="" />
          <div className="headdingherosection">
            <h2>Modern Delivery</h2>
          </div>
        </div>
      </div>
      <div className="cloud-detailes-wrapper">
        <div className="cloud-detailes-wrapper-headding">
          <div className="detailes-wrapper-headding-left">
            <img src="/assets/img/ModernDelivery.svg" width="100%" alt="" />
          </div>
          <div className="detailes-wrapper-headding-right">
            <h3>Comming Soon</h3>

            <div className="Cloude-services-wrapper">
              {/* <h3>Lorem Ipsum is simply</h3>
              <ul>
                <li>Lorem Ipsum is simply dummy text of the printing</li>
                <li>Lorem Ipsum is simply dummy text of the printing</li>
                <li>Lorem Ipsum is simply dummy text of the printing</li>
                <li>Lorem Ipsum is simply dummy text of the printing</li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </motion.div>
  );
}

export default ModernDelivery;
